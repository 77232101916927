import React, {useState} from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { Form, Field } from 'react-final-form'
import { isValidEmail } from "../validations"
import './NewsletterSubscriptionForm.css';

const ADD_NEWSLETTERSUBSCRIBER = gql`mutation createNewsletterSubscriber($email: String!){
  createNewsletterSubscriber(input:{data: { email: $email }}){
    newsletterSubscriber{
      id
    }
  }
}
`;

const NewsletterSubscriptionForm = () => {
  const [registered, setRegistered] = useState(false)
  const [onSubmission, setOnSubmission] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState("Successfully subscribed to the Neural Patches newsletter")
  const [subscriptionError, setSubscriptionError] = useState(null)
  const [AddNewsletterSubscriber] = useMutation(ADD_NEWSLETTERSUBSCRIBER);  
  
  const SubmitNewsletterSubscriptionForm = (payload) =>{
    setOnSubmission(true)
    setSubscriptionError("")
    AddNewsletterSubscriber({ variables: { email: payload.newsletterSubscription.email } }).then(function (value) {
      setOnSubmission(false)
      setRegistered(true)
      setNotificationMessage("Successfully subscribed to the Neural Patches newsletter")
    }, function (err) {
      setOnSubmission(false)
      if(err.message.includes("SQLITE_CONSTRAINT: UNIQUE constraint failed: newsletter_subscribers.email")){
        setOnSubmission(false)
        setRegistered(true)
        setNotificationMessage("This email was already subscribed to the newsletter, Thank you.")
      }else{
        setRegistered(false)
        setSubscriptionError("An error occured with your subscription, check your internet connection and try again. If the problem persists contact the administrator.")
      }
    });
    
  }

  return (
    <div style={{ marginBottom:"1em" }}>
    <Form
      onSubmit={SubmitNewsletterSubscriptionForm}
      //initialValues={initialState}
      validate={(values) => {
        let errors = {}
        if(!values.newsletterSubscription || isValidEmail(values.newsletterSubscription.email)){
          errors.email = "Invalid Email" 
        }
        return errors
      }}

      render={({ handleSubmit, submitting, valid, submitSucceeded }) => (
        <div>
          <form onSubmit={handleSubmit} className={`${registered ? "hidden" : ""}`}>
            <span >Subscribe to our newsletter</span>
            <div style={{display: "flex",justifyContent: "flex-start"}}>
              <Field type="email" name={"newsletterSubscription[email]"} maxLength="254" component="input" placeholder="* EMAIL" style={{display:'inline-block',margin:"unset", color:"white"}}/>
              <button className={"formSubmit radius"} type="submit" disabled={submitting || !valid || registered} style={{padding:"0 1.5em 0 1.5em",margin:"unset"}}>Subscribe</button>
            </div>
          </form>
          { onSubmission ?
          <div style={{display: "flex", justifyContent: "center"}}><small style={{fontWeight:"bold"}}>Sending...</small></div>
          : null
        }
        { registered ?
          <div style={{display: "flex", justifyContent: "center"}}><small style={{color: "#69EBF1", fontWeight:"bold"}}>{notificationMessage}</small></div>
          : null
        }
        { subscriptionError ?
          <div style={{display: "flex", justifyContent: "center"}}><small className="errorMessage">{subscriptionError}</small></div>
          : null
        }
        </div>
      )}
    />
    </div>
  );
}

export default NewsletterSubscriptionForm;
