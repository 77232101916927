import React from 'react';
import { Switch, Redirect, Router, Route } from 'react-router-dom';
import MainMenu from './components/MainMenu.js';
import MobileMenu from './components/MobileMenu.js';
import PackGrid from './components/PackGrid.js';
import RedeemPurchase from './components/RedeemPurchase.js';
import OrderConfirmed from './components/OrderConfirmed.js';
import Modal from "./components/Modal/Modal.js";
import {ApolloClient} from 'apollo-boost'
import {ApolloProvider} from 'react-apollo'
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { createBrowserHistory } from 'history'
import './App.css';
import ReactGA from 'react-ga'

const cache = new InMemoryCache();

const link = new HttpLink({
  uri: process.env.REACT_APP_API_ENDPOINT+'/graphql',
  cache: new InMemoryCache()
})

const client = new ApolloClient({cache,link});

const history = createBrowserHistory()
history.listen(location => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
})

class App extends React.Component {
  state = { 
    modalIsShowing: true,  
    modalData: {},
    modalType: "",
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname)
  }

  showModal = (type, data) => {
    this.setState({ modalIsShowing: true });
    this.setState({ modalType: type });
    this.setState({ modalData: data });
  };

  toggleModal = (e) => {
    this.setState({ modalIsShowing: !this.state.showModal });
  };

  hideModal = (e) => {
    this.setState({ modalIsShowing: false });
    this.setState({ modalData: {} });
    this.setState({ modalType: "" });
  };

  render() { 
    return (
    <ApolloProvider client={client}>
    <Router history={history}>

    <div className="App">
      <div className="main">
      <MainMenu showModal={this.showModal} toggleModal={this.toggleModal}/>
      <MobileMenu showModal={this.showModal} toggleModal={this.toggleModal}/>
        <div id="main_area">
        <div id="content_loader" style={{paddingBottom:"2em"}}>
        <Switch>
        <Route exact path={["/"]} render={props => (
          <React.Fragment>
            <PackGrid showModal={this.showModal} toggleModal={this.toggleModal} />
          </React.Fragment>
          )} 
        />

        <Route exact path="/order-confirmed" render={props => (
          <React.Fragment>
            <OrderConfirmed {...props} />
          </React.Fragment>
          )} 
        />

        <Route exact path="/redeem-purchase/:purchase_id/:security_key" render={props => (
          <React.Fragment>
            <RedeemPurchase {...props} />
          </React.Fragment>
          )} 
        />

        <Route exact path="/pack-group/:pack_group" render={props => (
           <React.Fragment>
           <PackGrid showModal={this.showModal} toggleModal={this.toggleModal} />
            <Modal {...props}
              type='pack-group'
              isShowing={this.state.modalIsShowing}
              hideModal={this.hideModal}
            />
          </React.Fragment>
          )} 
        />


        <Route exact path="/news" render={(props) => {
          return (
            <div>
              <PackGrid showModal={this.showModal} toggleModal={this.toggleModal} />
          
              <Modal 
                type='news'
                isShowing={this.state.modalIsShowing}
                hideModal={this.hideModal}/>
            </div>
          );
        }} 
        />

        <Route exact path="/subscribe" render={(props) => {
          return (
            <div>
              <PackGrid showModal={this.showModal} toggleModal={this.toggleModal} />
          
              <Modal 
                type='subscribe'
                isShowing={this.state.modalIsShowing}
                hideModal={this.hideModal}/>
            </div>
          );
        }} 
        />

        <Route exact path="/about"  render={() => {
          return (
            <div>
            <PackGrid showModal={this.showModal} toggleModal={this.toggleModal} />
            <Modal 
              type='about'
              isShowing={this.state.modalIsShowing}
              hideModal={this.hideModal}
            />
            </div>
          );
        }} 
        />

        <Route exact path="/contact" render={() => {
          return (
            <div>
            <PackGrid showModal={this.showModal} toggleModal={this.toggleModal} />
            <Modal 
              type='contact'
              isShowing={this.state.modalIsShowing}
              hideModal={this.hideModal}
            />
            </div>
          );
        }}/>
        <Route render={() => <Redirect to="/" />} />
        </Switch>
        
        </div>

        </div>

      </div>
    </div>
    <div
      id="footer"
      style={{
        color:'white',
        display:'flex',
        'justifyContent':'center',
        position: 'fixed',
        bottom:'0',
        width:'100%',
        fontSize:'1.2em'
      }}
    >
      <span>Copyright © 2020 - {new Date().getFullYear()} Neural Patches</span>
      </div>
    </Router>
    </ApolloProvider>
    );
  }
}

export default App;
