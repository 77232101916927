import React from 'react';
import './PackGrid.css';
import PackGroup from './PackGroup.js';
import '../full.css';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

var elementSize = "4";

const PACK_QUERY = gql`query PackGroupsQuery {
    packGroups(where:{is_active:true}){
      id,
      name, 
      synth,
      title,
      packs{
        volume,
      },
      cover{
        url
      }
    } 
  }
`;

export class PackGrid extends React.Component{

render(){
  return (
    <div id="siteloader" data-jdpage="jdpage-videos" data-parent="" data-ultimate-parent="videos">
      <div id="jdpage-videos">
        <div id="videos" style={{padding:'100px'}}>
          <div className="column videoContainerColumn">
          <div className="row patches-row" style={{minHeight:'100%', backgroundColor: 'transparent'}}>
  
          <div className="small-12 large-12 large-centered columns" > 
            <div id="jdvideos" className={elementSize === 4 ? 'short-jdvideos' : null}>

              {/*
                Size 4 = 3 or less
                Size 3 = 4-8
                Size 2 = 10-18
                Size 1 = 18 <
              */}

              <Query query={PACK_QUERY}>
              {
                ({loading, error, data}) => {
                  if(loading) return <h4>...loading</h4>;
                  if(error) return <h4>{error}</h4>;
                  //console.log(data.packGroups)

                  //if data.packGroups.length >0
                    //elementSize = 4
                  if (data.packGroups.length >=4)
                    elementSize = 3
                  if (data.packGroups.length >=10)
                    elementSize = 2
                  if (data.packGroups.length >=19)
                    elementSize = 1

                  return <React.Fragment >
                  {
                    data.packGroups.map(packGroup => (
                      <PackGroup gridElementSize={elementSize} key={packGroup.id} packGroup={packGroup} showModal={this.props.showModal}/>
                    ))
                  }
                  </React.Fragment>;
                }
              }
              </Query>
            </div>
          </div>
  
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
}

export default PackGrid;
