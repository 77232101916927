import React from 'react';
import '../full.css';
import './MobileMenu.css';
import {NavLink} from 'react-router-dom'

class MobileMenu extends React.Component {
  state = { 
    mobileMenuIsOpen: false,  
  };

  openMobileMenu = (e) => {
    this.setState({mobileMenuIsOpen:!this.state.mobileMenuIsOpen});
  }

  render() {
    return (
      <div className="row">
        <div className="large-12 jd-topbar hide-for-medium-up">
          <nav className={"top-bar " + (this.state.mobileMenuIsOpen ? 'menuOpened' : '')}>
            <ul className="title-area">
              <li>
                <a className="logo" href="/">Neural Patches</a>
              </li>
              <li className="toggle-topbar">
                <input className="menu-btn" type="checkbox" id="menu-btn" />
                <label className="menu-icon" htmlFor="menu-btn" onClick={e => this.openMobileMenu(e)}><span className="navicon"></span></label>
              </li>
            </ul>

            <section className="top-bar-section">
              <ul className="left jd-menu">
                <li className="divider"></li>
                <li id="msub-1" className="jdfirst"><NavLink to="/" onClick={e => this.openMobileMenu(e)}>Home</NavLink></li>
                <li className="divider"></li>
                <li id="msub-2"><NavLink to="/news" onClick={this.props.showModal.bind(this, 'news','news')} activeClassName="activeLink">News</NavLink></li>
                <li className="divider"></li>
                <li id="msub-3"><NavLink to="/about" onClick={this.props.showModal.bind(this, 'about','about')} activeClassName="activeLink">About</NavLink></li>
                <li className="divider"></li>
                <li id="msub-4"><NavLink to="/contact" onClick={this.props.showModal.bind(this, 'contact','contact')} activeClassName="activeLink">Contact</NavLink></li>
                <li className="divider"></li>
                <li id="msub-4"><NavLink to="/subscribe" onClick={this.props.showModal.bind(this, 'subscribe','subscribe')} activeClassName="activeLink">Join our Newsletter</NavLink></li>
                <li className="divider"></li>
              </ul>
            </section>
          </nav>
        </div>
      </div>
    );
  }
}
export default MobileMenu;
