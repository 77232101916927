import React, { Fragment } from 'react';
import '../full.css';
import './OrderConfirmed.css';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import BlockLoader from './BlockLoader';

let PURCHASES_QUERY = gql`query PurchasesQuery($paypal_transaction_id: String!) {
    
  purchases(where:{merchant_transaction_id:$paypal_transaction_id, is_expired:false}){
      id,
      merchant_transaction_id,
      created_at,
      security_key,
      client,
      is_expired,
      expiration_date,
      pack{
        pack_group{
          title,
          name,
          synth
        },
        volume,
        file{url},
        cover{url},
        downloaded_filename
      },
    }
  }`;
  
export class OrderConfirmed extends React.Component{
  state = { 
    merchant_transaction_id: "",
  };

componentDidMount(){
  let transaction_id = new URLSearchParams(this.props.location.search).get('tx')
  this.setState({merchant_transaction_id: transaction_id})
}

ValidatePaypalPurchase = (data) => {
  if(data.purchases.length < 1){
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/order-processing/validatePaypalOrder`,{
        method: "POST",
        encoding: 'utf-8',
        body: JSON.stringify({transaction_id: this.state.merchant_transaction_id}),
      }).then((res) => res.json()).then((r) => {
        if (r.response_code === 1){
          window.location = `/redeem-purchase/${r.payload.purchase_id}/${r.payload.security_key}`
        }else{
          window.location = `/`;
        }
      }).catch((error) => {
        console.error('ERROR:', error);
      });
  }
}

render(){

  return (
    <div id="order-confirmed" style={{padding:'1 0px'}}>
      <div className="row patches-row" style={{minHeight:'100%', backgroundColor: 'transparent'}}>
      <div className="large-10 small-12 large-centered columns">
      {(this.state.merchant_transaction_id) ?
          <Query query={PURCHASES_QUERY} onCompleted={(data)=>this.ValidatePaypalPurchase(data)} variables={{"paypal_transaction_id":this.state.merchant_transaction_id}}>
          {
            ({loading, error, data}) => {
              if(loading) return <div style={{color:"white"}} className="purchaseContainer">
                      <h2 style={{color:"white"}}>Please Wait...</h2>
                      <BlockLoader style={{height:"10rem"}}/>
                      <p>Please wait a few seconds while we validate your purchase and prepare your donwload link.</p>
                    </div>;
              if(error) return <h4>{error}</h4>;
              if(data.purchases.length < 1){
                  return <div style={{color:"white"}} className="purchaseContainer">
                      <h2 style={{color:"white"}}>Retrieving Purchase Details...</h2>
                      <BlockLoader style={{height:"10rem"}}/>
                      <p>Please wait a few seconds while we validate your purchase and prepare your donwload link.</p>
                    </div>;
              }
              return <Fragment >
                {
                  data.purchases.map(purchase => (
                    <div key={purchase.id} style={{color:"white"}} className="purchaseContainer">
                      <h2 style={{color:"white"}}>Purchase validated</h2>
                      <p>Generating your download link...</p>
                      <Redirect to={`/redeem-purchase/${purchase.id}/${purchase.security_key}`} />
                    </div>
                  ))
                }
              </Fragment>
            }
          }
          </Query>
        : null
      }
        </div>
      </div>
    </div>
  );
}
}

export default OrderConfirmed;
