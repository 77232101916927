import React from 'react';
import ContactForm from "./ContactForm";

class Contact extends React.Component {
  render() {
    return (
      <div id="contact" className="small-12 large-12 columns">
        <div className="panel">
          <h4 className="modal-title">Contact us</h4>
          <small>Here you can reach to us and let us know of any questions, comments, concerns or just drop a few lines to say thanks. We'll get back to you as soon as possible.</small>
          <ContactForm />
         </div>
      </div>
    );
}}
export default Contact;
