import React from "react"
import './BlockLoader.css';

export default ({ children, className, height = 400, ...props }) => {
  const classNameArr = [
    "BlockLoader position-relative js-loading-svg--block transparent",
    className
  ]
  classNameArr.push(`height-${height}`)
  return (
    <div {...props} className={classNameArr.join(" ")}>
      {children}
    </div>
  )
}
