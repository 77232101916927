import React, { Fragment } from 'react';
import '../full.css';
import './RedeemPurchase.css';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import Moment from 'react-moment';

let PURCHASES_QUERY = gql`query PurchasesQuery($id: Int!, $security_key: String!) {
    
  purchases(where:{id: $id, security_key:$security_key,is_expired:false}){
      id,
      created_at,
      security_key,
      client,
      is_expired,
      expiration_date,
      pack{
        pack_group{
          title,
          name,
          synth
        },
        volume,
        file{url},
        cover{url},
        downloaded_filename
      },
    }
  }`;

export class RedeemPurchase extends React.Component{
formatPackName = (pack) => {
    return `${pack.pack_group.title} ${pack.pack_group.synth} Volume: ${pack.volume}`
  };

formatPackFileName = (pack) => {
  if(pack.downloaded_filename){
    return pack.downloaded_filename
  }

  let filename = pack.pack_group.name;
  if(pack.volume)
    return `${filename}_vol_${pack.volume.trim()}_neural_patches.zip`
  else
    return `${filename}_neural_patches.zip`
  };
  
downloadFile= (e, purchase, id, key) =>{
  e.preventDefault();
  fetch(`${process.env.REACT_APP_API_ENDPOINT}${purchase.pack.file.url}`,).then(response => {
    response.blob().then(blob => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = this.formatPackFileName(purchase.pack);
      a.click();
    });
  });
}
render(){
  let security_key = this.props.match.params.security_key;  
  let purchase_id = parseInt(this.props.match.params.purchase_id);

  return (
    <div id="redeem-purchase" style={{padding:'1 0px'}}>
      <div className="row patches-row" style={{minHeight:'100%', backgroundColor: 'transparent'}}>
      <div className="large-10 small-12 large-centered columns">
          <Query query={PURCHASES_QUERY} variables={{"id":purchase_id,"security_key":security_key}}>
          {
            ({loading, error, data}) => {
              if(loading) return <h4>...loading</h4>;
              if(error) return <h4>{error}</h4>;
              if(data.purchases.length < 1){
                return <Fragment >{
                  <Redirect to="/" />
                }
                </Fragment>
              }
              return <Fragment >
                {
                  data.purchases.map(purchase => (
                    <div key={purchase.id} style={{color:"white"}} className="purchaseContainer">
                      <h2 style={{color:"white"}}>Thank you for purchasing {this.formatPackName(purchase.pack)}</h2>
                      <p>Please use your personal download link below.<br />
                      Download your patch pack and store it somewhere safe, this link will remain active for 30 days.<br />
                      In the unfortunate event that you lose your stored pack, just drop us a message using the contact form.</p>
                      <div className="small-12 small-centered large-centered columns" style={{marginBottom:"2em"}}> 
                        <div className="large-4 small-12  columns" >   
                          <img alt="patch set cover" src={process.env.REACT_APP_API_ENDPOINT+purchase.pack.cover.url}/>
                        </div>

                        <table style={{ margin:"auto" }}>
                          <tbody>
                          <tr>
                            <td><span className="label">Invoice Number:</span>
                            </td>
                            <td>{purchase.id}
                            </td>
                          </tr>

                          <tr>
                            <td><span className="label">Purchase date:</span>
                            </td>
                            <td><Moment format='ddd, MMM Do YYYY. hh:mm A'>{purchase.created_at}</Moment>
                            </td>
                          </tr>

                          <tr>
                            <td><span className="label">Download Link:</span>
                            </td>
                            <td><a rel="noopener noreferrer" href={`/redeem-purchase/${purchase_id}/${security_key}`} style={{fontWeight:"bold"}} onClick={e =>this.downloadFile(e, purchase,purchase_id,security_key)}>Download your patches</a>
                            </td>
                          </tr>

                          <tr>
                            <td><span className="label">Expiration date:</span>
                            </td>
                            <td><Moment format='ddd, MMM Do YYYY. hh:mm A'>{purchase.expiration_date}</Moment>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))
                }
              </Fragment>
            }
          }
          </Query>
        </div>
      </div>
    </div>
  );
}
}

export default RedeemPurchase;
