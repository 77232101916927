import React from 'react';
import './MainMenu.css';
import '../full.css';
import {NavLink, Link} from 'react-router-dom'
import NewsletterSubscriptionForm from './Modal/NewsletterSubscriptionForm'; 
class MainMenu extends React.Component {

  render() {
    return (
      <div className="row" >
        <div className="column">
          <div className="headerContainer" >
            <div className="jd-header hide-for-small">
              <div className="medium-12 columns">
                <div className="jdwrapper">
                  <div className="jdmenu">
                    <ul className="jdrow">

                      <li className="jditem">
                        <div className="jdlogo">
                          <Link to="/" className="logo" >Neural Patches</Link>
                        </div>
                      </li>

                      <li className="jditem sep"><span>|</span></li>

                      <li className="jditem separator"></li>

                        <li id="menu-5" className="jditem">
                          <NavLink className="toplevel" to="/news" onClick={this.props.showModal.bind(this, 'news','news')} activeClassName='activeLink'><span>News</span></NavLink>
                        </li>
                        
                        <li className="jditem separator"><span>|</span></li>

                        <li id="menu-43" className="jditem">
                          <NavLink className="toplevel" to="/about" onClick={this.props.showModal.bind(this, 'about','about')} activeClassName="activeLink"><span>About</span></NavLink>
                        </li>
                        
                        <li className="jditem separator"><span>|</span></li>

                        <li id="menu-9" className="jditem">
                          <NavLink className="toplevel" to="/contact" onClick={this.props.showModal.bind(this, 'contact','contact')} activeClassName="activeLink"><span>Contact</span></NavLink>
                        </li>
                        <li className="jditem separator"><span>|</span></li>
                        
                        <li id="menu-5" className="jditem">
                          <NavLink className="toplevel" to="/subscribe" onClick={this.props.showModal.bind(this, 'subscribe','subscribe')} activeClassName='activeLink'><span>Join our newsletter</span></NavLink>
                        </li>

                        <li className="jditem jdlast separator"><span>|</span></li>
                        
                        <li className="jditem sep"><span>|</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MainMenu;
