import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import ReactHtmlParser from 'react-html-parser';
import Moment from 'react-moment';
import './News.css';
import NewsletterSubscriptionForm from './NewsletterSubscriptionForm';

const NEWSPOSTS_QUERY = gql`query NewsPostsQuery{
  newsPosts(where:{is_published:true},sort:"published_on:desc"){
  id,
    title,
    content,
    type,
    created_at,
    published_on,
    is_published
  } 
}
`;

class Subscribe extends React.Component {
  state = {
  };

  render() {

  return (
    <div id="news" className="small-12 large-12 columns">
      <div className="panel">
      <h4 className="modal-title">Join our newsletter</h4>
      <p>Subscribe to our newsletter with your email and get notified of new releases and important announcements.</p>
        <NewsletterSubscriptionForm />
      </div>
    </div>
  );
}}

export default Subscribe;
