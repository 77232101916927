import React from 'react';
import './PackGrid.css';
import '../full.css';
import {Link} from 'react-router-dom'

function PackGroup(props) {
  const jditemsStyleObj = {opacity: 1};

  return (
        <div className={'large-'+props.gridElementSize+' small-12 columns jditems'}  style={jditemsStyleObj}>
          <Link to={"/pack-group/"+props.packGroup.name} className="wrapper" onClick={props.showModal.bind(this, 'packGroup', props.packGroup)}>
            <div className="shadow">
              <img alt="pack group cover" src={process.env.REACT_APP_API_ENDPOINT+props.packGroup.cover.url} />
            </div>
            <h2 className="packTitle">{props.packGroup.synth}<br/>
              <span className="packSubtitle">
                {`${props.packGroup.title} `}
                {props.packGroup.packs.map((pack, i) => (
                  <span key={i}>Vol {pack.volume}</span>
                  )).reduce((accu, elem) => {
                      return accu === null ? [elem] : [...accu, ', ', elem]
                  }, null)
                }
              </span>
            </h2>
          </Link>
        </div>
  );
}

export default PackGroup;
