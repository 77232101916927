import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import ReactHtmlParser from 'react-html-parser'; 
import './About.css';
import ReactPlayer from 'react-player'
import './PackGroupContents.css';
import {Redirect} from 'react-router-dom';

const PACKGROUP_CONTENTS_QUERY = gql`query PackGroupContentsQuery($group_name: String!) {
    packGroups(where:{ is_active:true, name: $group_name }){
      id,
      description,
      name,
      title,
      synth,
      demo_video,
      demo_audio,
      packs{
        id,
        demo_video,
        demo_audio,
        tagline,
        description,
        cover{
          url
        },
        patch_count,
        original_price,
        price,
        volume,
        is_active,
        buy_button
      },
      cover{
        url
      }
    }
  }
`;

class PackGroupContents extends React.Component {

  getOriginalPrice = (price) =>{
      return parseFloat(((parseFloat(price)/2)*3).toPrecision(3)).toFixed(2);
  }

  showcaseCover= (packgroup, pack) => {
      this.props.onLightboxShowcase(process.env.REACT_APP_API_ENDPOINT+pack.cover.url, `${packgroup.title} - ${packgroup.synth}: Volume ${pack.volume} Cover`)
  }

  render() {
    let pack_group = this.props.pack_group; 
    
    return (

      <div id="about" className="small-12 large-12 columns">
        <div className="panel">
          <Query query={PACKGROUP_CONTENTS_QUERY} variables={{"group_name":pack_group}}>
            {
              ({loading, error, data}) => {
                if(loading) return <h4>...loading</h4>;
                if(error) return <h4>{error}</h4>;
                //console.log(data.packGroups[0].packs)
                if(!data.packGroups[0]){
                 return <Redirect to="/" />;
                }

                return <React.Fragment>
                <h4 className="modal-title">{data.packGroups[0].title} for the {data.packGroups[0].synth}</h4>
                <div className="patch-group-description">{ ReactHtmlParser (data.packGroups[0].description) }</div>

                 {
                    data.packGroups[0].demo_video ? (<div style={{marginTop:"1.5rem"}}>
                      <small>Preview:</small>
                      <ReactPlayer url={data.packGroups[0].demo_video} width={"100%"} style={{display:"inline-block"}} volume={1} config={{youtube:{playerVars:{modestbranding:1}}}} controls={true}/>
                      </div>
                    )
                    : null
                  }

                  { data.packGroups[0].demo_audio ?
                    <div style={{marginTop:"1.5rem"}}>
                    <small>Audio only Preview:</small>
                    <div>{ReactHtmlParser (data.packGroups[0].demo_audio)}</div>
                    </div>
                    : null
                  }

                { 
                  data.packGroups[0].packs.map(pack => (
                    <div key={pack.id}>

                    <h5 style={{marginTop:"2rem"}}>• Volume {pack.volume}</h5>
                     {
                        pack.demo_video ? (<div style={{marginBottom:"1rem"}}>
                          <small>Preview:</small>
                          <div className="small-12 video-container">
                            <ReactPlayer url={pack.demo_video} width="100%" style={{display:"inline-block"}} volume={1} config={{youtube:{playerVars:{modestbranding:1}}}} controls={true}/>
                          </div>
                          </div>
                        )
                        : null
                      }
                      { pack.demo_audio ?
                        <div style={{marginBottom:"1rem"}}>
                        <small>Audio only Preview:</small>
                        <div>{ReactHtmlParser (pack.demo_audio)}</div>
                        </div>
                        : null
                      }
                      <div className="small-12 large-2 columns" style={{alignSelf:"center"}}>
                        <img alt="pack cover" className="patch-cover" src={process.env.REACT_APP_API_ENDPOINT+pack.cover.url} onClick={() => this.showcaseCover(data.packGroups[0],pack)} />
                      </div>
                      <div className="small-12 large-10 columns patch-info-container">
                          <span className="patch-price">${pack.price}</span><span className="patch-offer">Launch offer <small className="patch-offer-details">(Regular price ${pack.original_price || this.getOriginalPrice(pack.price)})</small></span><br />
                          <span className="patch-header">{data.packGroups[0].title}</span> for the {data.packGroups[0].synth} • <span className="patch-volume">{`Volume ${pack.volume}`}</span> • <span className="patch-patch_count">{pack.patch_count} patches</span>
                          <br/>
                          <span className="patch-download-type">INSTANT DIGITAL DOWNLOAD</span>
                        
                          <div style={{marginTop:"0.5rem"}}>
                            { ReactHtmlParser (pack.buy_button) }
                          </div>
                      </div>
                      <div style={{clear:"both"}}></div>
                    </div>
                  ))
                }
                </React.Fragment>;
              }
            }
          </Query>
        </div>
      </div>
    );
  }
}

export default PackGroupContents;
