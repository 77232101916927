import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import ReactHtmlParser from 'react-html-parser'; 
import './About.css';

const ABOUT_QUERY = gql`query PackGroupsQuery {
    about{
      text
    } 
  }
`;

class About extends React.Component {
  render() {
    return (

      <div id="about" className="small-12 large-12 ">
        <div className="panel">
          <Query query={ABOUT_QUERY}>
              {
                ({loading, error, data}) => {
                  if(loading) return <h4>...loading</h4>;
                  if(error) return <h4>{error}</h4>;
                  //console.log(data.packGroups)
                  
                  return <React.Fragment >
                  {
                      <div>
                        <h4 className="modal-title">About Neural Patches</h4>
                        { ReactHtmlParser (data.about.text) }
                      </div>
                  }
                  </React.Fragment>;
                }
              }
              </Query>
        </div>
      </div>
    );
  }
}
export default About;
