import React from 'react';
import Popup from "reactjs-popup";
import Contact from "./Contact";
import About from "./About";
import News from "./News";
import Subscribe from "./Subscribe";
import PackGroupContents from "./PackGroupContents";
import { Transition } from 'react-spring/renderprops'
import './Modal.css';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

class Modal extends React.Component {
  state = { 
    lightboxImage: "",
    lightboxTitle: "",
    pack: {},
  };
  
  showcaseLightbox = (img, title) =>{
    this.setState({lightboxImage: img})
    this.setState({lightboxTitle: title})
  }

  endCoverShowcase= () => {
    this.setState({ lightboxImage: ""})
    this.setState({lightboxTitle: ""})
  }

  render() {
    let modalContent;
    if (this.props.type === 'contact') {
      modalContent = <Contact/>
    }else if(this.props.type === 'about') {
      modalContent = <About/>
    }else if(this.props.type === 'news') {
      modalContent = <News/>
    }else if(this.props.type === 'subscribe') {
      modalContent = <Subscribe/>
    }else if(this.props.type === 'pack-group') {
      let pack_group = this.props.match.params.pack_group;  
      modalContent = <PackGroupContents onLightboxShowcase={this.showcaseLightbox} pack_group={pack_group}/>
    }

    return (
      <Transition
        items={this.props.isShowing}
        from= {{
          opacity: 0,
          transform: "scale(1.1)"
        }}
        enter= {{
          opacity: 1,
          transform: "scale(1)"
        }}
        leave= {{
          opacity: 0,
          transform: "scale(1.1)"
        }}
        config={{duration:200}}
      >
      { modalIsShowing => modalIsShowing && (springProps => 
        <Popup 
          open={this.props.isShowing}
          onClose={this.props.hideModal}
          springStyle={{springProps}}
          closeOnEscape={true}
        >
          <div>
            { this.state.lightboxImage ?
            <Lightbox keyboardInteraction={true} image={this.state.lightboxImage} title={this.state.lightboxTitle} onClose={this.endCoverShowcase} allowZoom={false} allowRotate={false} />
            : null }
            <div className="modal" style={springProps}>
              <a className="close" href="#close" onClick={this.props.hideModal}>
                &times;
              </a>
              {modalContent ? modalContent : null}
            </div>
          </div>
        </Popup>
        )
      }
      </Transition>
    );
  }
}
export default Modal;
