import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import ReactHtmlParser from 'react-html-parser';
import Moment from 'react-moment';
import './News.css';
import NewsletterSubscriptionForm from './NewsletterSubscriptionForm';

const NEWSPOSTS_QUERY = gql`query NewsPostsQuery{
  newsPosts(where:{is_published:true},sort:"published_on:desc"){
  id,
    title,
    content,
    type,
    created_at,
    published_on,
    is_published
  } 
}
`;

class News extends React.Component {
  state = {
  };

  render() {

  return (
    <div id="news" className="small-12 large-12 columns">
      <div className="panel">
      <h4 className="modal-title">News</h4>

      <NewsletterSubscriptionForm />

        <Query query={NEWSPOSTS_QUERY}>
            {
              ({loading, error, data}) => {
                if(loading) return <h4>...loading</h4>;
                if(error) return <h4>{error}</h4>;
                
                return <React.Fragment >
                {
                  data.newsPosts.map(newsPost => (
                    <div key={newsPost.id}>
                    <div>
                      <div className="news-post-title">{newsPost.title}</div>
                      <div className="news-post-subtitle"><div className="news-post-type">{newsPost.type}</div> • <Moment format='LLLL' className="news-post-created_at">{newsPost.published_on ? newsPost.published_on : null}</Moment></div>
                    </div>
                    <div className="news-post-content">
                      { ReactHtmlParser (newsPost.content) }
                    </div> 
                    </div>
                  ))
                }
                </React.Fragment>;
              }
            }
            </Query>
      </div>
    </div>
  );
}}

export default News;
