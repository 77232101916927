import React, {useState} from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import './ContactForm.css';
import { Form, Field } from 'react-final-form'
import { isValidEmail } from "../validations"

const SEND_CONTACT_MESSAGE = gql`mutation createContactFormSubmission($name: String!, $subject: String!, $email: String!, $message: String!){
  createContactFormSubmission(input:{data: { name: $name, subject: $subject, email: $email, message: $message }}){
    contactFormSubmission{
      id
    }
  }
}
`;

const ContactForm = () => {
  const [messaged, setMessaged] = useState(false)
  const [onSubmission, setOnSubmission] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState("")
  const [messagingError, setMessagingError] = useState(null)
  const [SendContactMessage] = useMutation(SEND_CONTACT_MESSAGE);  
  
  const SubmitContactForm = (payload) =>{
    setOnSubmission(true)
    setMessagingError("")
    SendContactMessage({ variables: { name: payload.contactForm.name, subject: `Neuralpatches.com Contact Form: `+(payload.contactForm.subject || "General Inquiry"), email: payload.contactForm.email, message: payload.contactForm.message } }).then(function (value) {
      setOnSubmission(false)
      setMessaged(true)
      setNotificationMessage("Message successfully sent, thank you.")
    }, function (err) {
      setOnSubmission(false)
      setMessaged(false)
      setMessagingError("An error occured sending your message, check your internet connection and try again.")
    });
  }

  return (
    <Form
      onSubmit={SubmitContactForm}
      validate={(values) => {
        let errors = {contactForm:{}}

        if(!values.contactForm || isValidEmail(values.contactForm.email)){
          errors.contactForm.email = "Invalid Email" 
        }

        if (!values.contactForm || !values.contactForm.name) {
          errors.contactForm.name = "This field is required"
        }

        if (!values.contactForm || !values.contactForm.message) {
          errors.contactForm.message = "This field is required"
        }
        return errors
      }}

      render={({ handleSubmit, submitting, valid, dirty, pristine, touched, submitSucceeded }) => (
        <div>
        <form onSubmit={handleSubmit} id="contact_form" className={`${messaged ? "hidden" : ""}`}>
          <fieldset>
            <Field type="hidden" name={"contactForm[nospam]"} component="input" value="true"/>
            <legend>Contact form</legend>
            <div className="row large-12 column">
            </div>

            <div className="row inputrow">
              <div className="large-12 columns">
                <Field name={"contactForm[name]"}>
                {({ input, meta }) => (
                  <div>
                    <input {...input} type="text" maxLength="50" placeholder="* Your Name" className={`${meta.error &&meta.touched ? "error" : ""}`}/>
                    {meta.error && meta.touched && <small className="errorMessage">{meta.error}</small>}
                  </div>
                )}
              </Field>
              </div>
            </div>

            <div className="row inputrow">
              <div className="large-12 columns">
                <Field type="text" name={"contactForm[subject]"} maxLength="50" component="input" placeholder="Subject" />
              </div>
            </div>

            <div className="row inputrow">
              <div className="large-12 columns">
                <Field name={"contactForm[email]"}>
                {({ input, meta }) => (
                  <div>
                    <input {...input} type="email" maxLength="254" placeholder="* Your Email" className={`${meta.error &&meta.touched ? "error" : ""}`}/>
                    {meta.error && meta.touched && <small className="errorMessage">{meta.error}</small>}
                  </div>
                )}
                </Field>
              </div>
            </div>

            <div className="row inputrow">
              <div className="large-12 columns">
                <Field name={"contactForm[message]"} >
                {({ input, meta }) => (
                  <div>
                    <textarea {...input} type="text" maxLength="2000" placeholder="* YOUR MESSAGE" className={`${meta.error &&meta.touched ? "error" : ""}`}/>
                    {meta.error && meta.touched && <small className="errorMessage">{meta.error}</small>}
                  </div>
                )}
                </Field>
              </div>
            </div>

            <div className="row inputrow" style={{backgroundColor:"black"}}>
              <div className="column large-12">
              { onSubmission ?
                <span style={{margin: "1rem 0 1rem 1rem", fontWeight:"bold", textAlign:"center"}}><small>Sending...</small></span>
                : null
              }
              { messagingError ?
                <span style={{marginLeft: "1rem", justifyContent: "center"}}><small className="errorMessage">{messagingError}</small></span>
                : null
              }
                <button name="contact_submit" className="button right radius" style={{padding:"0.2em 0.5em"}} disabled={submitting || !valid || messaged} type="submit">SEND</button>
              </div>
            </div>

          </fieldset>
        </form>
        { messaged?
          <div style={{margin: "1rem 0 1rem 1rem", fontWeight:"bold", textAlign:"center"}}><small style={{color: "#69EBF1"}}>{notificationMessage}</small></div>
          : null
        }

        
        </div>
      )}
    />
  );
}
export default ContactForm;
